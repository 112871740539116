<template>
    <div v-if="config">
        <div class="mb-2">
            <b-button class="mr-1" variant="success" @click="showRankReward=true; showAchivementReward=false">Rank Reward</b-button>
            <b-button variant="warning" @click="showAchivementReward=true; showRankReward=false">Achivement Reward</b-button>
        </div>
        <rank-reward v-show="showRankReward" :config="config" :rewards="rewards" :reward_options="reward_options" />
        <achivement-reward v-show="showAchivementReward" :config="config" :rewards="rewards" :reward_options="reward_options"></achivement-reward>
    </div>
</template>
<script>
import service from '../service';
import RankReward from './components/RankReward'
import AchivementReward from './components/AchivementReward'
export default {
    components: {
        RankReward,
        AchivementReward
    },
    data() {
        return {
            config: null,
            reward_config: null,
            achivement_config: null,
            fields: [
                { key: 'position', stickyColumn: true, isRowHeader: true, variant: 'primary' },
                "iron",
                "bronze",
                "silver",
                "gold",
                "platinum",
                "diamond",
                { key: "action", label: "", stickyColumn: true, isRowHeader: true },
            ],
            reward_options: [],
            rewards: [],
            levels: {
                "iron": 1,
                "bronze": 2,
                "silver": 3,
                "gold": 4,
                "platinum": 5,
                "diamond": 6,
            },
            configId: null,
            showRankReward: true,
            showAchivementReward: false,
        }
    },
    created() {
        this.getConfig();
        this.getUsableItem();
    },
    methods: {
        async getUsableItem() {
            let data = await service.getAllReward();
            if (data) {
                if (this.reward_options.length) {
                    this.reward_options = [];
                }
                this.reward_options = data.map((item) => {
                    return {
                        text: item.title || item.name,
                        value: item.type
                    }
                })
                this.rewards = data.map((item) => {
                    return {
                        reward_id: item._id,
                        type: item.type,
                        category: item.category
                    }
                })
                this.reward_options.push(...[{ text: 'Coin', value: 'coin' }, { text: 'Unlimited Lives', value: 'unlimited_lives' }]);
                this.rewards.push(...[{ type: 'coin' }, { type: 'unlimited_lives' }])
            }
        },
        async getConfig() {
            let config = await service.getConfig({ key: "GAME_CONFIG" });
            this.config = config;
        },
    }
}
</script>
<style lang="scss">
.config-table {
    table {
        td {
            padding-top: 0.72rem !important;
            padding-bottom: 0.72rem !important;

            p {
                margin: unset;
            }
        }

        td[aria-colindex="8"] {
            width: 120px;
        }

        .flex-end {
            justify-content: flex-end
        }
    }

    .add-config {
        width: 100%;

        button {
            margin: auto;
            display: block;
        }
    }

    .column-level {
        min-width: 300px !important;

        .check-box {
            display: flex;
            align-items: center;
        }

        input {
            padding: 0 30% !important;
            height: 30px;
            margin: 4px 0;
        }
    }
}
</style>
