import api from '@/libs/axios'

export default {
  async getConfig(params) {
    let response = await api.get('/admin/configs/detail', { params })
    return response.data.data.document || null
  },
  async getAllReward(params) {
    let response = await api.get("/admin/usable-item/all", {
      params
    });
    return response.data.data.list || null;
  },
  async updateConfig(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
}