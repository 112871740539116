<template>
    <div class="config-table">
        <b-table :items="config_value.tile_rank.rank_reward" :fields="fields" responsive>
            <template #cell(position)="data">
                <div v-if="configId == data.index">
                    <b-form-input v-model="data.item.start_position" placeholder="start" />
                    <b-form-input v-model="data.item.end_position" placeholder="end" />
                </div>
                <p v-else>{{ getPosition(data.item) }}</p>
            </template>
            <template #cell(iron)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.iron]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(bronze)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.bronze]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(silver)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.silver]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(gold)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.gold]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(platinum)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.platinum]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(diamond)="data">
                <div class="column-level">
                    <reward-list :config="data.item.reward[levels.diamond]" :reward_options="reward_options"
                        :rewards="rewards" :isEditing="configId == data.index"></reward-list>
                </div>
            </template>
            <template #cell(action)="data">
                <div class="d-flex flex-end">
                    <b-button @click="editOrUpdateConfig(data.index)" size="sm" style="margin-right: 5px"
                        variant="primary">{{ configId == data.index ? "Save" : "Edit" }}</b-button>
                    <b-button size="sm" variant="danger" @click="confirmDelete(data.index)">{{ configId == data.index ?
                        "Cancel" : "Delete" }}</b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>
import service from '../../service';
import RewardList from './RewardList';
import defaultObject from '../../defaultObject.js'
export default {
    components: {
        RewardList
    },
    props: {
        config: {type: Object, required: true},
        rewards: {type: Array, required: true},
        reward_options: {type: Array, required: true},
    },
    data() {
        return {
            fields: [
                { key: 'position', stickyColumn: true, isRowHeader: true, variant: 'primary' },
                "iron",
                "bronze",
                "silver",
                "gold",
                "platinum",
                "diamond",
                { key: "action", label: "", stickyColumn: true, isRowHeader: true },
            ],
            levels: {
                "iron": "iron",
                "bronze": "bronze",
                "silver": "silver",
                "gold": "gold",
                "platinum": "platinum",
                "diamond": "diamond",
            },
            configId: null,
            config_value: JSON.parse(JSON.stringify(this.config.value)),
        }
    },
    methods: {
        getPosition(config) {
            if (!config.end_position || !config.start_position) {
                return config.start_position ? '>=' + config.start_position : config.end_position + '<='
            }

            if (config.end_position === config.start_position) {
                return config.end_position
            }

            return config.start_position + '-' + config.end_position
        },

        getRewardAmount(reward, type) {
            const rw = reward.find(rw => rw.type === type)
            return rw.is_active ? rw.amount : '--'
        },

        async editOrUpdateConfig(index) {
            if (!(this.configId + '') || this.configId != index) {
                this.configId = index;
            } else {
                const response = await this.updateConfigs();
                if (response.data.type == "SUCCESS") {
                    this.configId = null;
                    // this.resetData();
                    this.$emit("getConfig");
                }
            }
        },

        async updateConfigs() {
            const dataUpdate = {
                ...this.config,
                value: this.config_value
            }
            console.log(dataUpdate)
            return await service.updateConfig(dataUpdate);
        },

        async cancelOrDelete(id) {
            if (this.configId == id) {
                this.resetData();
            } else {
                this.configs = [...this.configs.splice(0, id), ...this.configs.slice(1)]
                await this.updateConfigs()
                this.$emit("getConfig");
            }
        },

        confirmDelete(id) {
            this.$bvModal
                .msgBoxConfirm("Are you sure ?", {
                    title: "Please Confirm",
                    size: "md",
                    okVariant: "danger",
                    okTitle: "Yes",
                    cancelTitle: "No",
                    cancelVariant: "outline-secondary",
                    hideHeaderClose: true,
                    centered: true,
                })
                .then((value) => {
                    if (value === true) {
                        this.cancelOrDelete(id);
                    }
                });
        },

        resetData() {
            this.configId = null;
            this.config_value = JSON.parse(JSON.stringify(this.config.value));
        },

        addConfig() {
            this.config_value.tile_rank.rank_reward.push(JSON.parse(JSON.stringify(defaultObject)));
            this.configId = this.config_value.tile_rank.rank_reward.length - 1;
        },
    }
}
</script>
<style lang="scss">
.config-table {
    table {
        td {
            padding-top: 0.72rem !important;
            padding-bottom: 0.72rem !important;

            p {
                margin: unset;
            }
        }

        td[aria-colindex="8"] {
            width: 120px;
        }

        .flex-end {
            justify-content: flex-end
        }
    }

    .add-config {
        width: 100%;

        button {
            margin: auto;
            display: block;
        }
    }

    .column-level {
        min-width: 300px !important;

        .check-box {
            display: flex;
            align-items: center;
        }

        input {
            padding: 0 30% !important;
            height: 30px;
            margin: 4px 0;
        }
    }
}
</style>
