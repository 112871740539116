<template>
    <div v-if="items">
        <b-row v-for="(item, index) in items" :key="(item.type || item._id) + index" class="justify-content-center mb-2">
            <b-col cols="5">
                <span>Type</span>
                <multi-select v-if="options" :id="`${id}-select-input`" :options="options" :multiple="false"
                    v-model="item.type" @select="selected($event, index)" 
                />
            </b-col>
            <b-col cols="5">
                <span>Quantity</span>
                <b-input v-model="item.amount" type="number" />
            </b-col>
            <b-col class="d-flex align-items-center" cols="1">
                <b-button variant="danger" size="sm" @click="deleteItem(index)">
                    <slot>
                        <feather-icon icon="Trash2Icon" />
                    </slot>
                </b-button>
            </b-col>
        </b-row>
        <div class="text-center">
            <b-button variant="success" @click="addItem">+ New Reward</b-button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reward-select',
    props: {
        id: {type: String, required: true},
        items: { type: Array, required: true },
        options: { type: Array, required: true },
        rewards: { type: Array, required: true},
        default_data: { type: Object, default: () => { return {} } },
    },
    methods: {
        addItem() {
            this.items.push(this.default_data)
        },
        deleteItem(index) {
            this.items.splice(index, 1);
        },
        selected(event, index){
            let selectedReward = this.rewards.find(item => event.value === item.type);
            this.$set(this.items, index, {
                type: selectedReward.type,
                quantity: this.items[index].quantity
            })
            this.$forceUpdate();
        },
    }
}
</script>