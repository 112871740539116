<template>
    <div style="width: 100%;">
        <div v-if="!isEditing">
            <div v-for="(reward, index) in config" :key="reward.type + index">
                <b-row>
                    <b-col cols="8">
                        {{ getRewardName(reward.type) }} 
                    </b-col>
                    <b-col cols="4">
                        {{ getRewardAmount(reward.type) }}
                    </b-col>
                </b-row>
            </div>
        </div>
        <reward-select v-else
            :items="config" 
            :options="reward_options" 
            :id="'tilepass_rewards'"
            :default_data="{type: null, amount: null}" 
            :rewards="rewards"
        />
    </div>
</template>
<script>
import RewardSelect from './RewardSelect.vue'
export default {
    components: {
        RewardSelect
    },
    props: {
        isEditing: { type: Boolean, default: false },
        config: { type: Array, required: true },
        reward_options: {type: Array, required: true},
        rewards: {type: Array, required: true},
        // rank: {type: String, required: true}
    },
    data(){
        return {
            
        }
    },
    methods: {
        getRewardAmount(type) {
            const rw = this.config.find(rw => rw.type === type)
            return rw.amount || '--'
        },
        getRewardName(key){
            const reward = this.reward_options.find(item => item.value === key);
            return reward?.text || null
        }
    }
}
</script>