<template>
    <div>
        <b-card class="mb-1">
            <h3>Iron</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.iron" 
                :options="reward_options" 
                :id="'iron_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <b-card class="mb-1">
            <h3>Bronze</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.bronze" 
                :options="reward_options" 
                :id="'bronze_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <b-card class="mb-1">
            <h3>Silver</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.silver" 
                :options="reward_options" 
                :id="'silver_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <b-card class="mb-1">
            <h3>Gold</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.gold" 
                :options="reward_options" 
                :id="'gold_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <b-card class="mb-1">
            <h3>Platinum</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.platinum" 
                :options="reward_options" 
                :id="'platinum_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <b-card class="mb-1">
            <h3>Diamond</h3>
            <reward-select
                :items="config_value.tile_rank.new_achivement_reward.diamond" 
                :options="reward_options" 
                :id="'diamond_achivement_reward'"
                :default_data="{type: null, amount: null}" 
                :rewards="rewards"
            />
        </b-card>
        <action-buttons update-button @update="update" :is-updating="isUpdating"
		/>
    </div>
</template>
<script>
import RewardSelect from './RewardSelect.vue';
import service from '../../service';
export default {
    components: {
        RewardSelect
    },
    props: {
        config: {type: Object, required: true},
        rewards: {type: Array, required: true},
        reward_options: {type: Array, required: true},
    },
    data(){
        return {
            config_value: JSON.parse(JSON.stringify(this.config.value)),
            isUpdating: false,
        }
    },
    methods: {
        async update(){
            const dataUpdate = {
                ...this.config,
                value: this.config_value
            }
            let response = await service.updateConfig(dataUpdate);
            if (response.data.key === 'success'){
                this.config_value = dataUpdate.value;
            }
        }
    }
}
</script>